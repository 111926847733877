import angular from 'angular'

angular.module('ArgonModule').controller('ContactStatusesCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    const data = $element.data('ngdata')

    $scope.form = {}
    // Current mappings
    $scope.form.statuses = data.statuses || {}
    // All statuses
    $scope.statusesList = data.statuses_list || []

    $scope.unusedStatuses = (currentStatus) => {
      const usedStatuses = []
      Object.keys($scope.form.statuses).map((k) => {
        if (k == currentStatus) return
        $scope.form.statuses[k]?.map((s) => usedStatuses.push(s.name))
      })
      $scope.statusesList.filter((s) => usedStatuses.indexOf(s.name) < 0)
    }
  },
])
