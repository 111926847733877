import angular from 'angular'
import { EmployeeContactsSearch } from '../models/employee_contacts_search'

angular.module('ArgonModule').directive('employeeContactsSearch', [
  '$timeout',
  function ($timeout) {
    return {
      link: ($scope, element, attributes) => {
        const options = JSON.parse(attributes.deviceSearch || '{}')
        $timeout(() => new EmployeeContactsSearch(element).activate(options))
      },
    }
  },
])
