import { isEmpty } from 'lodash-es'

// You can use angular directive `selected-object` directive to set initial value
export class EmployeeContactsSearch {
  constructor(element) {
    this.element = element
  }

  activate() {
    const $input = $(this.element)

    $input.select2({
      minimumInputLength: 3,
      allowClear: true,
      placeholder: $input.attr('placeholder'),
      url: $input.data('action'),
      ajax: {
        url: $input.data('action'),
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params) => ({ q: params }),
        results: (data) => ({ results: data.items }),
      },
      formatNoMatches: () => '',
      formatResult: (employee) => {
        const notes = isEmpty(employee.notes) ? '' : `(${employee.notes})`
        return `${employee.name} (${employee.employee_number}) ${notes}`
      },
      formatSelection: (employee) => `${employee.name} (${employee.employee_number})`,
    })
  }
}
