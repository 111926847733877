import angular from 'angular'
import { values, forEach, each, size, last, keys, isEmpty } from 'lodash-es'
import { t } from './custom.service.messages'
import { customer_device_path, bill_customer_device_path } from '~/helpers/rails-routes'
import { sanitize } from 'dompurify/dist/purify.js'

// All columns which contain cost or usage values should use the following
// naming pattern: ..._cost_total ..._cost_average ..._usage_total ..._usage_average
// This pattern also should be aplied to their JSON keys

angular.module('ArgonModule').service('crColumnsService', [
  'dateFilter',
  'formatter',
  function (dateFilter, formatter) {
    const self = this

    const returns = () => ({
      init: (config) => {
        self.accounting_types_columns = config.accounting_types
        self.accounting_description_columns = config.accounting_category_descriptions

        const bunits = values(self.accounting_types_columns)
        forEach(self.accounting_description_columns, (desc_name) => bunits.push(desc_name))

        self.columns.push({
          id: 'business_unit',
          title: 'Business Unit',
          description: `(${bunits.join(', ')})`,
          group: tr_group('device_details'),
        })

        forEach(self.accounting_types_columns, (type_name, type_id) => {
          self.columns.push({
            id: type_id,
            title: type_name,
            // we don't need to display a checkbox for this column
            group: '',
            className: 'dt-right',
            data: (row) => JSON.parse(row[type_id] || '[]').join('<br/>'),
          })
        })

        forEach(self.accounting_description_columns, (desc_name, desc) => {
          self.columns.push({
            id: desc,
            title: desc_name,
            // we don't need to display a checkbox for this column
            group: '',
            className: 'dt-right',
            data: (row) => JSON.parse(row[desc] || '[]').join('<br/>'),
          })
        })

        transformColumns()
      },
      columns_grouped_for_select: () => {
        const column_groups = {}
        each(self.columns, (column_definition) => {
          if (!column_groups[column_definition.group]) column_groups[column_definition.group] = []
          column_groups[column_definition.group].push(column_definition)
          return true
        })
        return column_groups
      },
      columns_as_object: () => self.columns_as_object,
      findDefinitions: findDefinitions,
      defaultColumns: () => [
        'bulk_edit',
        'username',
        'number',
        'status',
        'device_make',
        'business_unit',
        'manager',
        'carrier',
        'rate_plans',
        'last_bdl_id',
      ],
      allColumns: () => self.columns.map((obj) => obj.id),
      lockedColumns: () => self.columns.filter((obj) => obj.locked).map((obj) => obj.id),
      groupColumns: (group) =>
        self.columns.filter((obj) => obj.group == group).map((obj) => obj.id),
      selectValidColumns: (columns) =>
        columns.filter((column) => self.columns_as_object[column] !== undefined),
    })

    //
    const findDefinitions = (columns) => {
      const result = []
      each(columns, (column_id) => {
        if (column_id != 'business_unit') {
          result.push(self.columns_as_object[column_id])
        } else {
          forEach(self.accounting_types_columns, (type_name, type_id) =>
            result.push(self.columns_as_object[type_id])
          )
          forEach(self.accounting_description_columns, (_, desc) =>
            result.push(self.columns_as_object[desc])
          )
        }
      })
      return result
    }

    const transformColumns = () => {
      self.columns_as_object = {}
      each(self.columns, (column_definition) => {
        self.columns_as_object[column_definition.id] = column_definition
        return true
      })
    }

    const tr = (key) => t(key)
    const tr_group = (key) => t(key)

    self.columns = [
      {
        id: 'bulk_edit',
        title: '',
        // we don't need to display a checkbox for this column
        group: '',
        locked: true,
        className: 'select-checkbox column_reordering_disabled',
        orderable: false,
        data: () => '',
      },
      {
        id: 'last_bdl_id',
        title: '',
        group: '',
        locked: true,
        className: 'hidden',
        data: () => '',
      },
      // Device Details
      {
        id: 'username',
        title: tr('name'),
        group: tr_group('user_details'),
        locked: true,
        className: 'column_reordering_disabled',
        data: (row, type) => {
          if (!row.username) row.username = ''
          if (type == 'display') {
            if (row.customer_id && row.device_id) {
              let username = row.username.substring(0, 15)
              if (username != row.username) username += '...'
              let html = `
                <a href='${customer_device_path(row.customer_id, row.device_id)}'
                  class='indicator secondary-branded secondary-branded-border'
                  title='${row.username}'>
                    ${username}
                </a>
              `
              if (row.last_bdl_id) {
                html += `
                  <a href='${bill_customer_device_path(
                    row.customer_id,
                    row.device_id,
                    row.last_bdl_id
                  )}'
                    class='label label-primary primary-branded-background individual-report-modal pull-right'
                    title='View Last Bill'>
                      <i class='fa fa-dollar-sign' aria-hidden='true'></i>
                  </a>
                `
              }
              return html
            } else {
              return formatter.formatPhone(row.number)
            }
          } else {
            return row.username
          }
        },
      },
      {
        id: 'number',
        title: tr('number'),
        group: tr_group('user_details'),
        locked: true,
        className: 'column_reordering_disabled dt-right',
        data: (row, type) => {
          if (type == 'display') {
            if (row.customer_id && row.device_id) {
              return `<a href='${customer_device_path(row.customer_id, row.device_id)}'
                class='indicator secondary-branded secondary-branded-border'
                title='${row.username}'>
                  ${sanitize(formatter.formatPhone(row.number))}
              </a>`
            } else {
              return sanitize(formatter.formatPhone(row.number))
            }
          } else if (type == 'filter') {
            return sanitize(row.number + ' ' + formatter.formatPhone(row.number))
          } else {
            return sanitize(row.number)
          }
        },
      },
      {
        id: 'status',
        title: tr('status'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display') {
            switch (row.status) {
              case 'active':
                return '<span class=green>Active</span>'
              case 'suspended':
                return '<span class=yellow>Suspended</span>'
              case 'cancelled':
                return '<span class=red>Cancelled</span>'
              default:
                return ''
            }
          } else {
            return row.status || ''
          }
        },
      },
      {
        id: 'billing_status',
        title: tr('billing_status'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display') {
            switch (row.billing_status) {
              case 'active':
                return '<span class=green>Active</span>'
              case 'suspended':
                return '<span class=yellow>Suspended</span>'
              case 'cancelled':
                return '<span class=red>Cancelled</span>'
              default:
                return ''
            }
          } else {
            return row.status || ''
          }
        },
      },
      {
        id: 'device_make',
        title: tr('make'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (!row.device_make) row.device_make = ''
          if (type == 'display') {
            return `<div style='white-space: normal;'>${row.device_make}</div>`
          } else {
            return `${row.device_make}`
          }
        },
      },
      {
        id: 'device_model_variant',
        title: tr('memory_size'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (!row.device_model_variant) row.device_model_variant = ''
          if (type == 'display') {
            return `<div style='white-space: normal;'>${row.device_model_variant}</div>`
          } else {
            return `${row.device_model_variant}`
          }
        },
      },
      {
        id: 'device_model',
        title: tr('model'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (!row.device_model) row.device_model = ''
          if (type == 'display') {
            return `<div style='white-space: normal;'>${row.device_model}</div>`
          } else {
            return row.device_model
          }
        },
      },
      {
        id: 'device_location',
        title: tr('location'),
        group: tr_group('user_details'),
        className: 'dt-right asdf-qwerty',
        data: (row) => sanitize(row.device_location),
      },
      // Other Costs
      {
        id: 'other_premium_messaging_cost_average',
        title: tr('premium_messaging_cost_avg'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.other_premium_messaging_cost_average),
      },
      {
        id: 'other_subscription_services_cost_average',
        title: tr('subscription_services_cost_avg'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.other_subscription_services_cost_average),
      },
      {
        id: 'other_411_services_cost_average',
        title: tr('411_services_cost_avg'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.other_411_services_cost_average),
      },
      //  Employee Details
      {
        id: 'employee_name',
        title: tr('hr_employee_name'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_name,
      },
      {
        id: 'employee_status',
        title: tr('employee_status'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_status,
      },
      {
        id: 'employee_terminated_date',
        title: tr('termination_date'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_terminated_date,
      },
      {
        id: 'employee_title',
        title: tr('employee_title'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_title,
      },
      {
        id: 'employee_email',
        title: tr('employee_email'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_email,
      },
      {
        id: 'employee_department_name',
        title: tr('employee_department_name'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_department_name,
      },
      {
        id: 'employee_hr_user_id',
        title: tr('employee_user_id'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_hr_user_id,
      },
      {
        id: 'employee_location',
        title: tr('employee_location'),
        group: tr_group('employee_details'),
        className: 'dt-right',
        data: (row) => row.employee_location,
      },

      // Carrier Details
      {
        id: 'carrier',
        title: tr('carrier'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => row.carrier,
      },
      {
        id: 'account_number',
        title: tr('account'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => row.account_number,
      },
      {
        id: 'rate_plans',
        title: tr('rate_plan'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row, type) => {
          const cutoff = 20
          let rate_plans = JSON.parse(row.rate_plans || '{}')
          let month
          if (size(rate_plans) > 1) month = last(keys(rate_plans)) || ''
          let rate_plan = last(values(rate_plans))

          let output = rate_plan || ''
          if (type == 'display') {
            if (month && rate_plan) output = `${rate_plan} (${month})`
            if (output.length > cutoff)
              output = `<span title='${output}'>${output.substr(0, cutoff)}…</span>`
          }
          return output
        },
      },
      {
        id: 'features',
        title: tr('features'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (!row.features) row.features = '{}'
          let features = JSON.parse(row.features)
          let month
          if (size(features) > 1) month = last(keys(features)) || ''
          features = keys(last(values(features)) || {})
          let html
          if (features.length > 2) {
            html = `
              <div>
                ${features.slice(0, 2).join('<br/>') || ''}
              </div>
              <div class='collapse' id='featuresCollapse${row.device_id}'>
                ${features.slice(1).join('<br/>') || ''}
              </div>
              <a href='#featuresCollapse${row.device_id}' data-toggle='collapse'
                class='secondary-branded secondary-branded-border'
                onclick='$(this).text(/all/.test($(this).text()) ? "hide" : "display all")'>
                display all
              </a>
            `
          } else {
            html = features.join('<br/>') || ''
          }

          if (type == 'display') return month && html ? `${month}: <br/> ${html}` : html
          else return row.features
        },
      },
      {
        id: 'contract_expiry_date',
        title: tr('contract_expiry'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display' && row.contract_expiry_date)
            return dateFilter(row.contract_expiry_date, 'MMM yyyy')
          else return row.contract_expiry_date
        },
      },
      {
        id: 'contract_balance',
        title: tr('contract_balance'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (type != 'display' && row.contract_balance == 'Unknown') return '-1.00'
          else return formatter.formatMoney(row.contract_balance)
        },
      },
      {
        id: 'upgrade_status',
        title: tr('upgrade_status'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => {
          if (!isEmpty(row.upgrade_status)) return formatter.formatMoney(row.upgrade_status)
          else return ''
        },
      },
      {
        id: 'eligibility',
        title: tr('eligibility'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => row.eligibility,
      },
      {
        id: 'early_upgrade_cost',
        title: tr('early_upgrade_cost'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => {
          if (row.early_upgrade_cost > 0) return formatter.formatMoney(row.early_upgrade_cost)
          else return ''
        },
      },
      {
        id: 'subsidized',
        title: tr('subsidized_plan'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => row.subsidized.charAt(0).toUpperCase() + row.subsidized.slice(1),
      },
      {
        id: 'device_initial_activation_date',
        title: tr('initial_activation_date'),
        group: tr_group('carrier_details'),
        className: 'dt-right',
        data: (row) => row.device_initial_activation_date,
      },

      // Device Details
      {
        id: 'imei_number',
        title: tr('carrier_imei'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.imei_number,
      },
      {
        id: 'sim_number',
        title: tr('sim'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.sim_number,
      },
      {
        id: 'static_ip',
        title: tr('static_ip'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.static_ip,
      },
      {
        id: 'private_apn_area',
        title: tr('private_apn_area'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.private_apn_area,
      },
      {
        id: 'device_mac_address',
        title: tr('mac_address'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.device_mac_address,
      },
      {
        id: 'last_upgraded_date',
        title: tr('last_upgraded_date'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.last_upgraded_date,
      },
      {
        id: 'mdm_last_update',
        title: tr('mdm_last_update'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.mdm_last_update,
      },
      {
        id: 'asset_tag',
        title: tr('asset_tag'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.asset_tag,
      },
      {
        id: 'device_used_for',
        title: tr('used_for'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.device_used_for,
      },
      {
        id: 'tags',
        title: tr('tags'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row, type) => {
          const tags = JSON.parse(row.tags) || []
          if (type == 'display') {
            return tags
              .map(
                (tag) => `<span class=badge style='background: #${tag.color};'>${tag.name}</span>`
              )
              .join('<br/>')
          } else {
            return tags.map((tag) => tag.name).join(' ')
          }
        },
      },
      {
        id: 'evergreen_date',
        title: tr('evergreen_date'),
        group: tr_group('device_details'),
        className: 'dt-right',
        data: (row) => row.evergreen_date,
      },

      // User Details
      {
        id: 'manager',
        title: tr('manager'),
        group: tr_group('user_details'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display') {
            try {
              return JSON.parse(row.manager).join('<br/>')
            } catch {
              return ''
            }
          } else {
            return row.manager
          }
        },
      },
      {
        id: 'additional_manager',
        title: tr('additional_manager'),
        group: tr_group('user_details'),
        className: 'dt-right',
        data: (row) => row.additional_manager,
      },
      {
        id: 'employee_number',
        title: tr('employee_number'),
        group: tr_group('user_details'),
        className: 'dt-right',
        data: (row) => row.employee_number,
      },
      {
        id: 'device_email',
        title: tr('email'),
        group: tr_group('user_details'),
        className: 'dt-right',
        data: (row) => row.device_email,
      },
      {
        id: 'device_last_note',
        title: tr('last_note'),
        group: tr_group('user_details'),
        className: 'dt-right',
        data: (row) => row.device_last_note,
      },

      // Airtime
      {
        id: 'airtime_cost_average',
        title: tr('airtime_cost_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.airtime_cost_average),
      },
      {
        id: 'airtime_usage_average',
        title: tr('airtime_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.airtime_usage_average),
      },
      {
        id: 'airtime_usage_total',
        title: tr('total_airtime_usage'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.airtime_usage_total),
      },
      {
        id: 'airtime_daytime_usage_average',
        title: tr('daytime_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.airtime_daytime_usage_average),
      },
      {
        id: 'airtime_evenings_and_weekends_usage_average',
        title: tr('evenings_and_weekends_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.airtime_evenings_and_weekends_usage_average),
      },
      {
        id: 'airtime_mobile_to_mobile_usage_average',
        title: tr('mobile_to_mobile_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.airtime_mobile_to_mobile_usage_average),
      },
      {
        id: 'long_distance_cost_average',
        title: tr('long_distance_cost_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_cost_average),
      },
      {
        id: 'long_distance_usage_average',
        title: tr('long_distance_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_usage_average),
      },
      {
        id: 'long_distance_usage_total',
        title: tr('total_long_distance_usage'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_usage_total),
      },
      {
        id: 'long_distance_cost_total',
        title: tr('total_long_distance_cost'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_cost_total),
      },
      {
        id: 'long_distance_domestic_cost_average',
        title: tr('long_distance_domestic_cost_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_domestic_cost_average),
      },
      {
        id: 'long_distance_domestic_usage_average',
        title: tr('long_distance_domestic_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_domestic_usage_average),
      },
      {
        id: 'long_distance_domestic_usage_total',
        title: tr('total_long_distance_domestic_usage'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_domestic_usage_total),
      },
      {
        id: 'long_distance_domestic_cost_total',
        title: tr('total_long_distance_domestic_cost'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_domestic_cost_total),
      },
      {
        id: 'long_distance_continental_cost_average',
        title: tr('long_distance_continental_cost_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_continental_cost_average),
      },
      {
        id: 'long_distance_continental_usage_average',
        title: tr('long_distance_continental_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_continental_usage_average),
      },
      {
        id: 'long_distance_continental_usage_total',
        title: tr('total_long_distance_continental_usage'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_continental_usage_total),
      },
      {
        id: 'long_distance_continental_cost_total',
        title: tr('total_long_distance_continental_cost'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_continental_cost_total),
      },
      {
        id: 'long_distance_international_cost_average',
        title: tr('long_distance_international_cost_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_international_cost_average),
      },
      {
        id: 'long_distance_international_usage_average',
        title: tr('long_distance_international_usage_avg'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_international_usage_average),
      },
      {
        id: 'long_distance_international_usage_total',
        title: tr('total_long_distance_international_usage'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.long_distance_international_usage_total),
      },
      {
        id: 'long_distance_international_cost_total',
        title: tr('total_long_distance_international_cost'),
        group: tr_group('airtime'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.long_distance_international_cost_total),
      },

      // Text
      {
        id: 'text_sent_cost_average',
        title: tr('sms_cost_avg'),
        group: tr_group('messaging'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.text_sent_cost_average),
      },
      {
        id: 'text_sent_usage_average',
        title: tr('sms_usage_avg'),
        group: tr_group('messaging'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.text_sent_usage_average),
      },
      {
        id: 'text_sent_usage_total',
        title: tr('total_sms_usage'),
        group: tr_group('messaging'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.text_sent_usage_total),
      },
      {
        id: 'text_mms_cost_average',
        title: tr('mms_cost_avg'),
        group: tr_group('messaging'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.text_mms_cost_average),
      },
      {
        id: 'text_mms_usage_average',
        title: tr('mms_usage_avg'),
        group: tr_group('messaging'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.text_mms_usage_average),
      },
      // Data
      {
        id: 'data_cost_total',
        title: tr('total_data_cost'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.data_cost_total),
      },
      {
        id: 'data_cost_average',
        title: tr('data_cost_avg'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.data_cost_average),
      },
      {
        id: 'daytime_data_usage_percentage',
        title: tr('daytime_data_usage'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatPercentage(row.daytime_data_usage_percentage),
      },
      {
        id: 'evenings_data_usage_percentage',
        title: tr('evenings_data_usage'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatPercentage(row.evenings_data_usage_percentage),
      },
      {
        id: 'weekend_data_usage_percentage',
        title: tr('weekend_data_usage'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row) => formatter.formatPercentage(row.weekend_data_usage_percentage),
      },
      {
        id: 'data_usage_average',
        title: tr('data_usage_avg'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display' && row.data_usage_average) {
            return formatter.formatData(row.data_usage_average)
          } else {
            return row.data_usage_average
          }
        },
      },
      {
        id: 'data_usage_total',
        title: tr('total_data_usage'),
        group: tr_group('data_and_other_costs'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display' && row.data_usage_total) {
            return formatter.formatData(row.data_usage_total)
          } else {
            return row.data_usage_total
          }
        },
      },
      // Roaming
      {
        id: 'roaming_cost_total',
        title: tr('total_roaming_cost'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_cost_total),
      },
      {
        id: 'roaming_cost_average',
        title: tr('roaming_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_cost_average),
      },
      {
        id: 'roaming_voice_cost_average',
        title: tr('roaming_voice_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_voice_cost_average),
      },
      {
        id: 'roaming_voice_usage_average',
        title: tr('roaming_voice_usage_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.roaming_voice_usage_average),
      },
      {
        id: 'roaming_text_cost_average',
        title: tr('roaming_text_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_text_cost_average),
      },
      {
        id: 'roaming_text_usage_average',
        title: tr('roaming_text_usage_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.roaming_text_usage_average),
      },
      {
        id: 'roaming_data_cost_average',
        title: tr('roaming_data_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_data_cost_average),
      },
      {
        id: 'roaming_data_usage_average',
        title: tr('roaming_data_usage_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display' && row.roaming_data_usage_average) {
            return formatter.formatData(row.roaming_data_usage_average)
          } else {
            return row.roaming_data_usage_average
          }
        },
      },
      {
        id: 'roaming_daily_continental_usage_total',
        title: tr('us_roaming_days'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.roaming_daily_continental_usage_total),
      },
      {
        id: 'roaming_daily_continental_cost_average',
        title: tr('us_roaming_days_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_daily_continental_cost_average),
      },
      {
        id: 'roaming_daily_international_usage_total',
        title: tr('int_roaming_days'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatNumber(row.roaming_daily_international_usage_total),
      },
      {
        id: 'roaming_daily_international_cost_average',
        title: tr('int_roaming_days_cost_avg'),
        group: tr_group('roaming'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.roaming_daily_international_cost_average),
      },
      // Costs & Averages
      {
        id: 'total_cost_total',
        title: tr('total_spend'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.total_cost_total),
      },
      {
        id: 'total_cost_average',
        title: tr('cost_avg'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.total_cost_average),
      },
      {
        id: 'highest_month_total',
        title: tr('highest_month'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display' && row.highest_month_total) {
            const formatted_date = dateFilter(row.highest_month, 'MMM yyyy')
            return [
              formatter.formatMoney(row.highest_month_total),
              '<br/> ',
              `<small>${formatted_date}</small>`,
            ].join('')
          } else {
            return row.highest_month_total
          }
        },
      },
      {
        id: 'credits_cost_total',
        title: tr('total_credits'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.credits_cost_total),
      },
      {
        id: 'features_cost_average',
        title: tr('features_cost_avg'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.features_cost_average),
      },
      {
        id: 'rate_plan_cost_average',
        title: tr('rate_plan_costs_avg'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.rate_plan_cost_average),
      },
      {
        id: 'taxes_gst_total',
        title: tr('total_gst'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.taxes_gst_total),
      },
      {
        id: 'taxes_pst_total',
        title: tr('total_pst'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.taxes_pst_total),
      },
      {
        id: 'taxes_hst_total',
        title: tr('total_hst'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.taxes_hst_total),
      },
      {
        id: 'taxes_qst_total',
        title: tr('total_qst'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.taxes_qst_total),
      },
      {
        id: 'cancellation_fees_charged_total',
        title: tr('cancellation_fees_charged'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.cancellation_fees_charged_total),
      },
      {
        id: 'total_hardware_charges',
        title: tr('total_hardware_charges'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.total_hardware_charges),
      },
      {
        id: 'early_upgrade_fee',
        title: tr('early_upgrade_fees'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.early_upgrade_fee),
      },
      {
        id: 'device_enrolment',
        title: tr('device_enrollment'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.device_enrolment),
      },
      {
        id: 'device_purchase',
        title: tr('device_purchase'),
        group: tr_group('cost_and_averages'),
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.device_purchase),
      },
    ]

    return returns()
  },
])
