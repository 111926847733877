$(() => new AdminAccountExecutives($('@adminAccountExecutivesContainer')))

class AdminAccountExecutives {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    const $accountExecutivesTable = $container.find('@adminAccountExecutivesTable')

    const searchSegment = $container.find('#search-segment')
    const searchText = $container.find('#search-text')
    const searchButton = $container.find('#search-button')

    $accountExecutivesTable.dataTable({
      bSort: false,
      filter: true,
      iDisplayLength: 10,
      bProcessing: true,
      bServerSide: true,
      sAjaxSource: '/admin/bell_account_executives/search.json',
      dom: 'lrtip',
      fnServerParams: (aoData) => {
        return aoData.push.apply(aoData, [
          { name: 'segment', value: searchSegment.val() },
          { name: 'sSearch', value: searchText.val() },
        ])
      },
    })

    searchButton.click(() => $accountExecutivesTable.dataTable().fnDraw())
    searchText.keypress((e) => {
      // Enter key support for search field
      if (e.which === 13) {
        searchButton.click()
      }
    })
  }
}
